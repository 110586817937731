<template>
  <div class="article-items">
    <!-- <div class="flex justify-between mb-3">
      <div></div>
      <el-radio-group v-model="model">
        <el-radio-button label="model-1">Modèle 1</el-radio-button>
        <el-radio-button label="model-2">Modèle 2</el-radio-button>
      </el-radio-group>
    </div> -->
    <el-table :data="items" border style="width: 100%">
      <el-table-column label="N°" prop="" width="40">
        <template slot-scope="scope">
          <!--   <div class="flex justify-between">
            <i
              @click="handleDelete(scope.$index)"
              class="el-icon-circle-close text-gray-500 text-2xl cursor-pointer"
            ></i>
          </div> -->
          <span class="font-bold"> {{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="article_name" label="DÉSIGNATION" min-width="250">
        <template slot-scope="scope">
          <div class="article-item-input" v-if="model === 'model-1'">
            <el-select
              size="medium"
              style="width: 100%"
              filterable
              v-model="scope.row.article_id"
              remote
              :remote-method="searchArticle"
              :loading="loadingArticle"
              placeholder="Recherche un article"
              clearable
              @change="
                selectArticle(scope.row.article_id, scope.row, scope.$index)
              "
            >
              <el-option
                v-for="item in articles"
                :key="item.article_id"
                :label="` ${item.article_name}`"
                :value="item.article_id"
              >
              </el-option>
            </el-select>
          </div>
          <span class="font-bold mt-7" v-if="model === 'model-2'">
            {{ scope.row.article_name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="ENTREPÔT" width="150">
        <template slot-scope="scope">
          <div class="article-item-input">
            <span
              v-if="loadingStock && scope.$index === rowIndex"
              v-loading="scope.$index === rowIndex"
              element-loading-spinner="el-icon-loading"
            ></span>
            <el-select
              v-model="scope.row.warehouse_code"
              class="w-full"
              size="medium"
              placeholder="selectionner l'entrepôt"
            >
              <el-option
                v-for="item in scope.row.stocks"
                :key="item.warehouse_id"
                :label="`${item.warehouse_name}`"
                :value="item.warehouse_code"
              >
              </el-option>
            </el-select>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="quantity"
        align="center"
        label="QUANTITÉ"
        width="110"
      >
        <template slot-scope="{ row }">
          <div class="input-transparent">
            <el-input-number
              size="medium"
              :controls="false"
              v-model="row.quantity"
              :min="1"
              @blur="checkStock(row)"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="PRIX" width="135">
        <template slot-scope="{ row }">
          <div class="input-transparent">
            <el-input-number
              size="medium"
              :controls="false"
              v-model="row.price"
              @blur="checkPrice(row)"
            />
          </div>
        </template>
      </el-table-column>
      <!-- 
      <el-table-column align="center" label="REMISE" width="160">
        <template slot-scope="{ row }">
          <div class="input-transparent">
          <el-input-number
              size="medium"
              :controls="false"
              v-model="row.discount"
              @blur="checkPrice(row)"
            /> 
            <el-input
              size="medium"
              v-model="row.discount"
              class="input-with-select"
              @blur="handleCheckDiscount(row)"
              @input.native="handleCheckDiscount(row)"
            >
              <el-select
                size="medium"
                style="width: 60px"
                v-model="row.percent_discount"
                slot="append"
                placeholder="Choisir"
                @change="handleDiscount(row)"
              >
                <el-option label="%" :value="true"></el-option>
                <el-option label="F" v-if="sale" :value="false"></el-option>
              </el-select>
            </el-input>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="taxe" align="center" label="TAXE" width="110">
        <template slot-scope="{ row }">
          <div class="article-item-input">
            <el-select
              size="medium"
              class="w-full"
              v-model="row.percent_tax"
              @change="handleSelectTaxe"
            >
              <el-option
                v-for="item in taxes"
                :key="item.value"
                :label="`${item.name}`"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </template>
      </el-table-column> -->

      <el-table-column align="right" label="TOTAL" width="130">
        <template slot-scope="{ row }">
          <span class="font-semibold mt-7">
            {{ calculTotalItem(row) | moneyFilter }}</span
          >
        </template>
      </el-table-column>
      <el-table-column align="center" width="80">
        <template slot-scope="{ row }">
          <i
            @click="handleDelete(row.article_id)"
            class="el-icon-circle-close text-red-500 text-2xl cursor-pointer mr-2"
          ></i>
          <!--   <el-popover
            v-if="sale"
            placement="top-start"
            :title="`Prix d'achat : ${row.price_purchase}`"
            width="200"
            trigger="hover"
          >
            <i
              slot="reference"
              class="el-icon-warning-outline text-blue text-2xl cursor-pointer"
            ></i
          ></el-popover> -->
        </template>
      </el-table-column>
    </el-table>
    <div class="mt-2">
      <el-button icon="el-icon-plus" type="infos" @click="addArticle"
        >Ajouter une ligne</el-button
      ><el-button icon="el-icon-plus" type="infos" @click="addFiveArticle"
        >Ajouter 5 lignes</el-button
      >
    </div>
    <!--   <div class="float-right">
      <div class="subtotal">
        <div class="item">
          <div class="label">Total HT</div>
          <div class="value">{{ subTotal | moneyFilter }} FCFA</div>
        </div>
        <div class="item">
          <div class="label">Remise</div>
          <div class="value">
            <el-input
              size="medium"
              v-model="resume.discount"
              class="input-with-select"
              @input.native="handlUpdateDiscount"
            >
              <el-select
                size="medium"
                style="width: 60px"
                v-model="resume.percent_discount"
                slot="append"
                placeholder="Choisir"
                @change="handlUpdatePercentDiscount"
              >
                <el-option label="%" :value="true"></el-option>
                <el-option label="F" v-if="sale" :value="false"></el-option>
              </el-select>
            </el-input>
          </div>
        </div>
        <div class="item">
          <div class="label">Taxe</div>
          <div class="value">
            <el-select
              size="medium"
              class="w-full"
              v-model="resume.percent_tax"
              @change="handlUpdateTaxe"
            >
              <el-option
                v-for="item in taxes"
                :key="item.value"
                :label="`${item.name}`"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="item">
          <div class="label">Autre coût</div>
          <div class="value">
            <el-input-number
              size="medium"
              :controls="false"
              v-model="resume.shipping"
              :min="0"
              @change="handlUpdateShipping"
            />
          </div>
        </div>
        <div class="item">
          <div class="label total">Montant TTC</div>
          <div class="value total">{{ totalTTC | moneyFilter }} FCFA</div>
        </div>
      </div>
    </div> -->

    <!--  <el-dialog
      title="SELECTIONNER UN ENTREPÔT"
      :visible.sync="dialogWarehouse"
      width="30%"
      top="20%"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div class="flex flex-col">
        <el-select
          v-model="updateStock.warehouse_code"
          class="w-full mb-2"
          placeholder="selectionner l'entrepôt "
        >
          <el-option
            v-for="item in updateStock.stocks"
            :key="item.warehouse_id"
            :label="`${item.warehouse_name}`"
            :value="item.warehouse_code"
          >
          </el-option>
        </el-select>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogWarehouse = false">Annuler</el-button>
        <el-button type="primary" @click="changeWarehouse">Valider</el-button>
      </div>
    </el-dialog> -->

    <!--   <el-dialog
      title="SELECTIONNER LES ARTICLES"
      :visible.sync="dialogArticle"
      width="70%"
      top="1%"
    >
      <div class="flex el-dialog-div">
        <div class="w-1/2">
          <el-select
            class="w-full mb-2"
            v-model="listQuery.warehouse_id"
            placeholder="selectionner l'entrepôt "
          >
            <el-option
              v-for="item in warehouses"
              :key="item.id"
              :label="`${item.name}`"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-input
            placeholder="Recherche un article"
            v-on:input.native="handleSearch"
            v-model="listQuery.name"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <div class="list-article" :Loading="listLoading">
            <div class="item" v-for="(item, index) in listArticle" :key="index">
              <span>{{ item.article_name }}</span>
            </div>
          </div>
        </div>

        <div class="w-1/2">Article</div>
      </div>
    </el-dialog>  -->
  </div>
</template>

<script>
import { searchArticle } from "@/api/article";
import { parseTime } from "@/utils";
import { toThousandFilter } from "@/Filters";
import { fetchStockOnHand } from "@/api/stock";
export default {
  name: "Add-Article-Voucher",
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    articles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialogArticle: false,
      dialogWarehouse: false,
      // articles: [],

      listArticle: [],
      loadingArticle: false,
      loadingStock: false,
      model: "model-1",
      taxes: [{ id: undefined, value: 0, name: "-Aucun-" }],
      updateStock: {
        warehouse_code: "",
        article_id: "",
        stocks: [],
      },
      rowIndex: undefined,
      stock: {
        article_id: null,
        quantity: 0,
        warehouse_code: 0,
        warehouse_name: "",
      },
      resume: {
        percent_tax: 0,
        percent_discount: true,
        discount: 0,
        shipping: 0,
      },
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        name: undefined,
        warehouse_code: undefined,
      },
    };
  },
  mounted() {},
  methods: {
    async searchArticle(query) {
      if (query.length !== "" && query.length > 2) {
        this.loadingArticle = true;
        await searchArticle({ name: query })
          .then((res) => {
            // this.articles = res.data;
            this.$emit("search-article", res.data);
            this.loadingArticle = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    selectArticle(articleId, row, index) {
      const checkDuplicat = this.items.filter(
        (el) => el.article_id === articleId
      ).length;
      if (checkDuplicat > 1) {
        row.article_id = "";
        this.$message({
          message:
            "L'article a été dejà ajouter, veuillez  modifier la quantité",
          type: "warning",
        });
      } else {
        this.rowIndex = index;
        const item = this.articles.find((el) => el.article_id === articleId);

        row.article_name = item.article_name;
        row.article_no = item.sku;
        row.price = this.sale ? item.price_sale : item.price_purchase;
        row.price_sale = item.price_sale;
        row.price_purchase = item.price_purchase;
        row.quantity = 1;
        this.fetchStockOnHand(row);
        // this.calculTotalItem(row);
      }
    },
    addArticle() {
      this.$emit("add-item-article", true);
      /*   this.items.push({
        article_id: "",
        article_no: "",
        article_name: "",
        price_purchase: 0,
        price_sale: 0,
        description: "",
        price: "",
        percent_tax: 0,
        percent_discount: false,
        discount: 0,
        quantity: 0,
        warehouse_code: "",
        warehouse_name: "",
        stock_onhand: "",
        stocks: [],
      }); */
    },
    addFiveArticle() {
      for (let i = 0; i < 4; i++) {
        this.addArticle();
      }
    },
    handleDelete(id) {
      this.$emit("delete", id);
    },
    async fetchStockOnHand(row) {
      this.loadingStock = true;
      await fetchStockOnHand(row.article_id)
        .then((res) => {
          setTimeout(() => {
            row.stocks = res.data;
            const stock = row.stocks.find((el) => el.has_primary === true);
            row.warehouse_code = stock.warehouse_code;
            row.warehouse_name = stock.warehouse_name;
            row.stock_onhand = stock.quantity;
            this.loadingStock = false;
          }, 1000);
        })
        .catch((error) => {
          this.$message.error(error.data.message);
        });
    },

    checkStock(row) {
      if (this.sale) {
        if (row.quantity > row.stock_onhand) {
          this.$confirm(
            `La quantité saisie ${row.quantity} est supérieur au stock disponible ${row.stock_onhand}, Vous souhaitez continuer ?`,
            "Warning",
            {
              distinguishCancelAndClose: true,
              showClose: false,
              confirmButtonText: "Continuer",
              cancelButtonText: "Modifier par la disponiblité",
              type: "warning",
            }
          )
            .then(() => {})
            .catch(() => {
              row.quantity = 1;
            });
        }
      }
      // this.calculTotalItem(row);
    },
    checkPrice(row) {
      if (this.sale) {
        if (row.price < row.price_sale) {
          this.$confirm(
            `Le prix saisi ${row.price} est inférieur au prix minimun ${row.price_sale} , Vous souhaitez continuer ?`,
            "Warning",
            {
              distinguishCancelAndClose: true,
              showClose: false,
              confirmButtonText: "Continuer",
              cancelButtonText: "Modifier par le minimun",
              type: "warning",
            }
          )
            .then(() => {})
            .catch(() => {
              row.price = row.price_sale;
            });
        }
      } else {
        if (row.price < row.price_purchase) {
          this.$confirm(
            `Le prix saisi ${row.price} est inférieur au prix minimun ${row.price_purchase} , Vous souhaitez continuer ?`,
            "Warning",
            {
              distinguishCancelAndClose: true,
              showClose: false,
              confirmButtonText: "Continuer",
              cancelButtonText: "Modifier par le minimun",
              type: "warning",
            }
          )
            .then(() => {})
            .catch(() => {
              row.price = row.price_purchase;
            });
        }
      }
    },
    calculTotalItem(row) {
      row.total = row.price * row.quantity;
      return row.total;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.subtotal {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-items: start;
  border: 1px solid $borderColor;
  .item {
    display: flex;
    border-bottom: 1px solid $borderColor;

    font-weight: bold;
    .label,
    .value {
      padding: 7px 5px;
    }
    .label {
      background: $bg-header-table;
      width: 50%;
      font-weight: bold;
    }
    .value {
      width: 50%;
      display: flex;
      justify-content: end;
      font-weight: bold;
    }
  }
  .total {
    color: $green-dark;
  }
}
.el-dialog-div {
  height: 70vh;
  overflow: hidden;
}
.list-article {
  height: 70vh;
  overflow-y: auto;
  .item {
    padding: 5px 0px;
    cursor: pointer;
    font-weight: 500;
  }
}
.article_name {
  font-weight: 500;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}
.article-desc {
  display: flex;
  padding-top: 5px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
}
.el-select .el-input {
  width: 50px;
}
</style>
