<template>
  <nav
    class="fixed-page-footer bg-white border-t border-tflex flex-col justify-between border-gray-200"
  >
    <slot></slot>
  </nav>
</template>

<script>
export default {
  name: "PageHeader",
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@import "@/styles/variables.scss";
.fixed-page-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
  overflow: hidden;
  //background: $header_bg; //#d5b513; //#f3f4f6; //#fff;
  box-shadow: 0px -1px 4px rgb(0 21 41 / 8%);
}
</style>
